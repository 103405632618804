<template>
    <div v-wechat-title="'就诊工作台'" class="h-screen flex flex-col">
        <div class="flex items-center px-15 py-10">
            <div class="w-150 h-40 flex items-center justify-around border-1 border-[#CECECE] rounded-full">
                <i class="icon iconfont iconarrow-left-filling" @click="handleChangeDate('subtract')"></i>
                <span class="date_info" @click="handleChangeDate('select')">{{ date || "请选择" }}</span>
                <i class="icon iconfont iconarrow-right-filling" @click="handleChangeDate('add')"></i>
            </div>
            <van-search
                class="ml-10 flex-1 h-40 rounded-full"
                input-align="center"
                background="#F8F7FC"
                v-model="tempKeyword"
                placeholder="患者姓名或手机号"
                @search="handleSearch"
            />
        </div>

        <van-calendar
            v-model="showCalendar"
            :default-date="new Date(date)"
            :min-date="new Date(2020, 0, 1)"
            :show-confirm="false"
            @confirm="(date) => handleChangeDate('confirm', date)"
        />

        <van-tabs v-model="tabsData.activeName" @change="(name) => handleChangeTab(name)" class="flex-1 bg-[#F6F6F4]" color="#CF8C57" title-active-color="#CF8C57" swipeable>
            <van-tab class="h-[calc(100vh-106px)] overflow-y-auto" v-for="tab in tabsData.tabList" :key="tab.name" :title="tab.title" :name="tab.name" :badge="tab.badge">
                <van-pull-refresh v-model="tabsData.refresh" @refresh="handleGetSeeData({ init: true })">
                    <div class="min-h-[calc(100vh-106px)]">
                        <div class="text-center py-60 text-[#898989] text-16" v-if="tab.noData">当天无预约记录</div>
                        <van-list
                            v-else
                            :ref="'vanList'"
                            :name="tab.name"
                            v-model="tab.loading"
                            :finished="tab.finished"
                            @load="handleGetSeeData"
                            :offset="30"
                            finished-text="加载完成"
                        >
                            <template #loading v-if="tabsData.refresh"><div></div></template>

                            <div class="px-15">
                                <div class="mt-20 px-20 py-15 rounded-10 bg-gradient-1 bg-white" v-for="item in tab.list" :key="item.prescriptionid + '_' + item.apponintmentid">
                                    <div class="flex items-center">
                                        <div class="text-16 leading-22 font-bold">{{ item.name }}</div>
                                        <div class="ml-6 px-3 h-16 rounded-3 bg-[#FF9933] text-center leading-16 text-10 text-white" v-if="item.appointmentnum">
                                            {{ item.appointmentnum }}
                                        </div>
                                        <div class="ml-6 px-3 h-16 rounded-3 bg-[#FF9933] text-center leading-16 text-10 text-white" v-if="item.seetypename">
                                            {{ item.seetypename }}
                                        </div>

                                        <div class="pl-6 flex-shrink-0 ml-auto text-14 leading-20 text-[#9D9D9D]" @click="medical(item.userid, item.customerid)">
                                            <span>查看病历</span>
                                            <van-icon name="arrow" />
                                        </div>
                                    </div>

                                    <div class="mt-8 text-[#9D9D9D] text-14 leading-20">
                                        <span>{{ item.sex }}</span>
                                        <span class="ml-4">{{ item.age }}岁</span>
                                        <span class="ml-4">{{ item.phone }}</span>
                                    </div>

                                    <div class="mt-10 h-1 bg-[#D8D8D8]"></div>

                                    <div class="mt-10 flex justify-between text-14 leading-20">
                                        <div class="text-[#555555] flex-shrink-0 mr-4">预约医生</div>
                                        <div class="text-[#9D9D9D]">{{ item.execdoctor }}</div>
                                    </div>
                                    <div class="mt-10 flex justify-between text-14 leading-20">
                                        <div class="text-[#555555] flex-shrink-0 mr-4">预约时间</div>
                                        <div class="text-[#9D9D9D]">{{ item.appointmenttime }}</div>
                                    </div>
                                    <div class="mt-10 flex justify-between text-14 leading-20" v-if="item.checknum && item.checknum != 0">
                                        <div class="text-[#555555] flex-shrink-0 mr-4">报到号</div>
                                        <div class="text-[#9D9D9D]">{{ item.checknum }}</div>
                                    </div>
                                    <div class="mt-10 flex justify-between text-14 leading-20">
                                        <div class="text-[#555555] flex-shrink-0 mr-4">病情</div>
                                        <div class="text-[#9D9D9D]">{{ item.state || "--" }}</div>
                                    </div>

                                    <div class="mt-15 flex flex-wrap justify-end text-14 leading-20">
                                        <div
                                            v-if="item.type == '1' && (tab.name == 0 || tab.name == 1)"
                                            class="ml-12 h-35 leading-35 px-15 text-[#555555] rounded-full border-1 border-[#CECECE]"
                                            @click="send(item.apponintmentid)"
                                        >
                                            推送预录病情
                                        </div>

                                        <div
                                            class="ml-12 h-35 leading-35 px-15 text-[#555555] rounded-full border-1 border-[#CECECE]"
                                            @click="takePicture(item.docid, item.userid)"
                                        >
                                            拍照
                                        </div>

                                        <div
                                            class="ml-12 h-35 leading-35 px-15 text-white hover:text-white focus:text-white font-bold rounded-full bg-[#CF8C57]"
                                            @click="handlePrescription(item)"
                                        >
                                            {{ tab.name == 0 || tab.name == 1 ? "接诊" : "继续接诊" }}
                                        </div>

                                        <div
                                            v-if="tab.name == 2"
                                            class="ml-12 h-35 leading-35 px-15 text-white hover:text-white focus:text-white font-bold rounded-full bg-[#CF8C57]"
                                            @click="handleConfirm(item)"
                                        >
                                            完成接诊
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </van-list>
                    </div>
                </van-pull-refresh>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
import dayjs from "dayjs";
import { wxseestatistics, wxseeqry, wxsendpreinfo, wxchangeappointrecordstatus, wxqryprescriptionnopaynum, wxfinishtreat } from "@/api/medical";
import { mapGetters } from "vuex";
import { Toast } from "vant";

export default {
    name: "seePage",
    components: {},
    data() {
        return {
            firstActivated: true,

            showCalendar: false,

            date: dayjs().format("YYYY-MM-DD"),
            keyword: "",
            tempKeyword: "",

            tabsData: {
                refresh: false,
                activeName: "0",
                tabList: [
                    { title: "已预约", name: "0", badge: 0, loading: false, noData: false, finished: false, page: 1, list: [] },
                    { title: "候诊中", name: "1", badge: 0, loading: false, noData: false, finished: false, page: 1, list: [] },
                    { title: "就诊中", name: "2", badge: 0, loading: false, noData: false, finished: false, page: 1, list: [] },
                    { title: "已完成", name: "3", badge: 0, loading: false, noData: false, finished: false, page: 1, list: [] },
                ],
            },
        };
    },
    computed: {
        ...mapGetters(["username", "clinicid"]),
    },
    watch: {},
    methods: {
        /**
         * 搜索
         */
        handleSearch() {
            this.keyword = this.tempKeyword;
            this.handleGetSeeData({ init: true });
        },

        /**
         * 切换日期
         * 类型 select：选择、confirm：日历确定、add：下一日、subtract：上一日
         * @param type
         */
        handleChangeDate(type, date) {
            const oldDate = this.date;

            switch (type) {
                case "add":
                    this.date = dayjs(this.date).add(1, "day").format("YYYY-MM-DD");
                    break;

                case "subtract":
                    this.date = dayjs(this.date).subtract(1, "day").format("YYYY-MM-DD");
                    break;

                case "select":
                    this.showCalendar = true;
                    break;

                case "confirm":
                    this.date = dayjs(date).format("YYYY-MM-DD");
                    this.showCalendar = false;
                    break;
            }

            if (oldDate != this.date) {
                this.handleGetSeeData({ init: true });
            }
        },

        /**
         * 切换tab
         */
        handleChangeTab(name) {
            const domList = this.$refs["vanList"];

            if (domList != null) {
                const dom = domList.find((item) => item.$attrs.name == name);

                if (dom) {
                    dom.check();
                }
            }
        },

        /**
         * 获取看病数据
         */
        async handleGetSeeData({ init } = {}) {
            const { tabList, activeName } = this.tabsData;

            if (init) {
                tabList.forEach((item) => {
                    item.badge = 0;
                    item.loading = item.name === activeName;
                    item.noData = false;
                    item.finished = false;
                    item.page = 1;
                    item.list = [];
                });

                this.getSeeStatistics();
            }

            const data = tabList.find((item) => item.name === activeName);

            try {
                const { response_body: res } = await wxseeqry({
                    qrydate: this.date,
                    keyword: this.keyword,
                    page: data.page,
                    qrystatus: activeName,
                    username: this.username,
                    clinicid: this.clinicid,
                });

                this.tabsData.refresh = false;

                if (!Array.isArray(res.list) || res.list.length === 0) {
                    data.noData = true;
                    return;
                }

                data.list = data.list.concat(res.list);
                data.page++;
                data.loading = false;
                data.badge = res.num;

                if (data.list.length >= res.num) {
                    data.finished = true;
                }
            } catch (error) {
                console.error(error);
            }
        },

        /**
         * 获取看病统计数据
         */
        async getSeeStatistics() {
            try {
                const { response_body: res } = await wxseestatistics({
                    qrydate: this.date,
                    keyword: this.keyword,
                    username: this.username,
                    clinicid: this.clinicid,
                });

                const tabKeyData = {
                    0: "notreached",
                    1: "wait",
                    2: "see",
                    3: "finish",
                };

                this.tabsData.tabList.forEach((item) => {
                    item.badge = res[tabKeyData[item.name]];
                });
            } catch (error) {
                console.error(error);
            }
        },

        medical(userid, customerid) {
            this.$router.push({
                path: "/medicalPage",
                query: {
                    userid: userid,
                    customerid: customerid,
                },
            });
        },

        send(appointmentid) {
            Toast({
                message: "准备推送预录病情消息至患者微信广州颐中中医公众号上...",
                type: "loading",
                duration: 0,
            });
            let data = {
                username: this.username,
                id: appointmentid,
            };
            wxsendpreinfo(data).then(() => {
                if (Toast) {
                    Toast.clear();
                }
                Toast.success("推送成功");
            });
        },
        takePicture(docId, userId) {
            this.$router.push({
                path: "/takePicture",
                query: {
                    docId: docId,
                    userId: userId,
                },
            });
        },

        /**
         * 处理开方
         */
        handlePrescription(item) {
            if (this.tabsData.activeName == 0 || this.tabsData.activeName == 1) {
                wxchangeappointrecordstatus({
                    username: this.username,
                    recordid: item.apponintmentid,
                });
            }

            const queryIds = ["prescriptionid", "apponintmentid", "userid", "customerid"];
            this.$router.push({
                path: "/prescription",
                query: Object.fromEntries(queryIds.filter((queryId) => item[queryId] != null).map((queryId) => [queryId, item[queryId]])),
            });
        },

        /**
         * 完成接诊
         */
        async handleConfirm(item) {
            try {
                this.$toast.loading({
                    message: "完成中...",
                    forbidClick: true,
                    duration: 0,
                });

                const { response_body: res } = await wxqryprescriptionnopaynum({
                    username: this.username,
                    id: item.prescriptionid,
                });

                if (res.msg) {
                    this.$toast.clear();

                    await this.$dialog.confirm({
                        message: res.msg,
                    });

                    this.$toast.loading({
                        message: "完成中...",
                        forbidClick: true,
                        duration: 0,
                    });
                }

                await wxfinishtreat({
                    username: this.username,
                    id: item.prescriptionid,
                    recordid: item.apponintmentid,
                });

                this.$toast.success("已完成");

                setTimeout(() => {
                    this.handleGetSeeData({ init: true });
                }, 2000);
            } catch (error) {
                console.error(error);
            }
        },
    },
    created() {
        this.$store.dispatch("hideOrShowNav", false);
        this.getSeeStatistics();
    },
    activated() {
        if (this.firstActivated) {
            this.firstActivated = false;
            return;
        }

        this.handleGetSeeData({ init: true });
    },
};
</script>
<style lang="scss" scoped>
.bg-gradient-1 {
    background-image: linear-gradient(to bottom, rgba(207, 140, 87, 0.2), transparent 75px);
}
</style>
